import React from 'react'
import Header from '../components/header';
import About from '../components/about';
import Hero from '../components/hero';
import Footer from '../components/footer';
import Performers from '../components/performers';
import Direction from '../components/direction';
import Ticket from '../components/ticket';
import Sponsors from '../components/sponsors';
import { motion } from "framer-motion";

const Home = () => {
  return (
    <motion.div
      initial={{opacity: 0, y: 200}}
      animate={{opacity: 1, y: 0}}
      exit={{opacity:0, y: 0}}
      transition={{delay: 0.25, type: "spring"}}
    >
      <Header />
      <Hero />
      <About />
      <Performers />
      <Ticket />
      <Sponsors />
      <Direction />
      <Footer />
    </motion.div>
  )
}

export default Home