import React, { useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';

const Direction = () => {

  useEffect(() => {
    AOS.init();
  }, []); 
  
  return (
    <section className="lg:flex lg:flex-row flex-col items-center justify-between w-[90%] mx-auto py-[90px]">
      <div 
        className="w-full lg:w-[50%]"
        data-aos="fade-left" data-aos-duration="1000"
      >
        <h2 className="text-red-600 font-bold font-body font-mont text-center text-2xl md:text-3xl lg:text-4xl xl:text-5xl leading-8 md:leading-10 lg:leading-12 xl:leading-14 mb-6 md:mb-8">Direction to the venue</h2>
        <div>
          <h3 className="text-red-600 font-body font-bold font-lato text-xl leading-6 md:leading-7 lg:leading-8 xl:leading-9">Venue</h3>
          <p className="text-red-600 font-body text-base font-lato md:text-lg lg:text-xl xl:text-2xl leading-6 md:leading-7 lg:leading-8 xl:leading-9">Transcorp Hilton</p>
        </div>

        <div className='mt-5'>
          <h3 className="text-red-600 font-body font-bold font-lato text-xl leading-6 md:leading-7 lg:leading-8 xl:leading-9">Address</h3>
          <p className="text-red-600 font-body text-base font-lato md:text-lg lg:text-xl xl:text-2xl leading-6 md:leading-7 lg:leading-8 xl:leading-9">1 Aguyi Ironsi St, Maitama 9000001, Abuja, FCT, Nigeria.</p>
        </div>
        <button className="bg-red-600 hover:bg-red-800 text-white font-lato font-body text-xl font-bold py-3 px-4 rounded mt-[30px] w-[200px]">Get Direction</button>
      </div>

      <div 
        className="lg:w-[40%] w-full mt-5 rounded-10 h-[500px]"
        data-aos="fade-right" data-aos-duration="1000"
      >
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3939.8844465304846!2d7.492405976070553!3d9.074290688276204!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x104dd42594163dd1%3A0x2b97d2308e5f8ee1!2sTranscorp%20Hilton%20Abuja!5e0!3m2!1sen!2sng!4v1700298611204!5m2!1sen!2sng" 
          className="rounded-10 mx-auto w-full h-full"
          style={{ border: "0" }}
          allowfullscreen="" 
          loading="lazy" 
          referrerpolicy="no-referrer-when-downgrade"
          title="Map of transcorp"
        ></iframe>
      </div>
    </section>
  )
}

export default Direction