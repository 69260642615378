import React, { useEffect } from 'react'
import kennyblaq from '../assets/kennyblaq.jpeg';
import josh from '../assets/josh2funny.jpeg';
import nedu from '../assets/nedu.jpg';
import chuks from '../assets/chuks.jpg';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Performers = () => {

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <section className='bg-red-600 text-center py-[30px]' id="performers">
      <div className=" w-[90%] mx-auto">
        <h2 className="text-white font-bold font-body font-mont text-2xl md:text-3xl lg:text-4xl xl:text-5xl leading-8 md:leading-10 lg:leading-12 xl:leading-14 mb-6 md:mb-8">Meet the Performers</h2>
        {/* <div className="grid grid-cols-4 gap-2"> */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-2">

          <div 
            className="p-4"
            data-aos="fade-up" data-aos-duration="1000"
          >
            <img 
              src={chuks}
              alt='chuks d general'
              className="rounded-full border border-gray-500 p-2 w-[300px] h-[300px]"
            />
            <p className="text-white font-medium text-base font-lato font-body md:text-lg lg:text-xl xl:text-2xl leading-6 md:leading-9 lg:leading-12 xl:leading-14 mt-[10px]">Chuks D General</p>
          </div>

          <div 
            className="p-4"
            data-aos="fade-down" data-aos-duration="1000"
          >
            <img 
              src={kennyblaq}
              alt='kennyblaq'
              className="rounded-full border border-gray-500 p-2 w-[300px] h-[300px]"
            />
            <p className="text-white font-medium text-base font-body font-lato md:text-lg lg:text-xl xl:text-2xl leading-6 md:leading-9 lg:leading-12 xl:leading-14 mt-[10px]">Kennyblaq</p>
          </div>

          <div 
            className="p-4"
            data-aos="fade-up" data-aos-duration="1000"
          >
            <img 
              src={josh}
              alt='josh2funny'
              className="rounded-full border border-gray-500 p-2 w-[300px] h-[300px]"
            />
            <p className="text-white font-medium text-base font-body font-lato md:text-lg lg:text-xl xl:text-2xl leading-6 md:leading-9 lg:leading-12 xl:leading-14 mt-[10px]">Josh2funny</p>
          </div>

          <div 
            className="p-4"
            data-aos="fade-left" data-aos-duration="1000"
          >
            <img 
              src={nedu}
              alt='nedu'
              className="rounded-full border border-gray-500 p-2 w-[300px] h-[300px] object-cover"
            />
            <p className="text-white font-medium text-base font-body font-lato md:text-lg lg:text-xl xl:text-2xl leading-6 md:leading-9 lg:leading-12 xl:leading-14 mt-[10px]">Nedu</p>
          </div>
          {/* josh2 funny, kennyblaq, chuks d general, nedu, mc fresh, mc ebeneza, mazi prsoper, sarkin dariya, dj fancy, mc nappy, job legend, aje baba */}
        </div>
        <div className="mt-10">
          <h2 className="text-red-900 font-medium text-base font-body md:text-xl lg:text-2xl xl:text-3xl leading-6 md:leading-9 lg:leading-12 xl:leading-14"> Also Featuring: </h2>
          <p className="text-white font-medium text-base font-lato font-body md:text-lg lg:text-xl xl:text-2xl leading-6 md:leading-9 lg:leading-12 xl:leading-14 mt-5">
            Mc Fresh, MC Ebennza, Mazi Prosper, Sarkin Dariya, DJ Fancy, MC Nappy, Job Legend, Aje Baba and many more...
          </p>
        </div>
      </div>
    </section>
  )
}

export default Performers