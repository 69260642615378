import React, { useRef, useState } from "react";
import { motion } from "framer-motion";
import "./style.css";
import { Link, useNavigate } from "react-router-dom";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
// import Footer from '../components/footer';
import TicketHeader from "../components/ticketHeader";
import Qrcode from "../assets/qr-code.png";
import pending from "../assets/pending.gif";
// import ticketBg from '../assets/ticket-bg.avif';

const Userticket = () => {
  const pdfRef = useRef();
  const [email, setEmail] = useState("");
  const [responseData, setResponseData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  // const openModal = () => {
  //   setIsModalOpen(true);
  // };

  const closeModal = () => {
    setIsModalOpen(false);
    navigate("/view-ticket");
  };

  const downloadPdf = () => {
    const input = pdfRef.current;
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4", true);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY = 30;
      pdf.addImage(
        imgData,
        "PNG",
        imgX,
        imgY,
        imgWidth * ratio,
        imgHeight * ratio
      );
      pdf.save("ticket.pdf");
    });
  };

  const handleGetRequest = async (e) => {
    e.preventDefault();
    try {
      if (!email.trim()) {
        setError("Please enter an email address");
        return;
      }

      setLoading(true);

      const apiUrl = `https://ticketingsystem-a38eed7cb83c.herokuapp.com/api/v1/ticket?email=${encodeURIComponent(
        email
      )}`;

      const response = await fetch(apiUrl);

      if (response.ok) {
        const data = await response.json();
        // console.log(data)

        if (response.status === 200) {
          if (data.body.status === "COMPLETED") {
            setResponseData(data.body);
            setError(null);
          } else if (data.body.status === "PENDING") {
            setResponseData(null);
            setIsModalOpen(true);
          } else {
            setIsModalOpen(true);
          }
        } else {
          // console.error('Error:', response.status, response.statusText);
          setError(data.body.message);
        }
      } else {
        // console.error('Error:', response.status, response.statusText);
        // setError('Error generating ticket, please try again')
        setError("Ticket not found");
      }
    } catch (error) {
      // console.error('Error:', error.message);
      setError("Error generating ticket, please try again");
    }
    setLoading(false);
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: 200 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 0 }}
        transition={{ delay: 0.25, type: "spring" }}
        className="h-full"
      >
        <TicketHeader />
        <div>
          {responseData ? (
            <div
              className="bg-yellow-100 p-8 rounded-lg my-6 shadow-lg max-w-md w-full mx-auto"
              ref={pdfRef}
            >
              <h1 className="text-3xl font-bold text-purple-800 mb-4">
                Christmas in-d-city
              </h1>

              <div className="mb-4">
                <p className="font-bold text-gray-800">
                  Date: 25th December, 2023
                </p>
                <p className="font-bold text-gray-800">Time: 5:00 PM</p>
                <p className="font-bold text-gray-800">
                  Location: Transcorp Hilton, Abuja
                </p>
              </div>

              <div className="mb-6">
                <p className="text-xl font-bold text-purple-800 mb-4">
                  {responseData.name}
                </p>
                <p className="text-sm text-gray-800 font-bold mb-2">
                  {responseData.email}
                </p>
                <p className="text-sm text-gray-800 font-bold mb-2">
                  Ticket ID: {responseData.ticketId}
                </p>
                <p className="text-sm text-gray-800 font-bold mb-2">
                  Ticket: {responseData.description}
                </p>
                <p className="text-sm text-gray-800 font-bold mb-2">
                  Date Purchased:{" "}
                  {new Date(responseData.datePurchased).toLocaleString()}
                </p>
                <p className="text-sm text-gray-800 font-bold mb-2">
                  Price: NGN {responseData.price}
                </p>
                <p className="text-sm text-gray-800 font-bold mb-2">
                  Quantity: {responseData.quantity}
                </p>
                <p className="text-sm text-gray-800 font-bold mb-2">
                  Payment Status: {responseData.status}
                </p>
              </div>

              <img src={Qrcode} alt="QR Code" className="mx-auto mb-4" />

              <p className="text-lg font-bold text-purple-800 mb-3 text-center">
                &copy; BitsChange from BitsSense Ltd
              </p>

              <button
                className="bg-purple-800 text-white px-6 py-2 rounded-md hover:bg-purple-600 focus:outline-none focus:border-purple-900 block mx-auto"
                onClick={downloadPdf}
              >
                Download Ticket
              </button>
            </div>
          ) : (
            <>
              <div className="text-center">
                <p className="text-red-600 font-body font-lato font-bold text-lg p-3">
                  Enter your email address to generate your ticket.
                </p>
              </div>
              <form
                onSubmit={handleGetRequest}
                className="shadow-lg lg:w-[600px] sm:w-[90%] p-5 mx-auto"
              >
                <div className="mb-[15px]">
                  <label className="text-red-600 font-body font-lato font-bold text-lg leading-6 md:leading-7 lg:leading-8 xl:leading-9">
                    EMAIL
                  </label>
                  <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="border border-gray-300 py-2.5 px-2 w-full rounded-md focus:outline-none focus:border-red-600 my-[5px]"
                  />
                  <span className="text-red-600">{error}</span>
                </div>

                <div className="flex lg:flex-row flex-col items-center justify-center my-[30px]">
                  <button
                    className="bg-red-600 hover:bg-red-800 text-white font-lato font-body text-xl font-bold py-3 px-4 rounded w-[220px] lg:mr-5 mt-6"
                    onClick={handleGetRequest}
                  >
                    {loading ? "Generating Ticket..." : "Generate Ticket"}
                  </button>
                  <Link to="/ticket">
                    <button className="bg-red-600 hover:bg-red-800 text-white font-lato font-body text-xl font-bold py-3 px-4 rounded w-[200px] lg:ml-5 mt-6">
                      Buy Ticket
                    </button>
                  </Link>
                </div>

                <div className="text-center">
                  <p className="text-red-600 font-body font-lato font-bold text-lg">
                    To stream Christmas in-d-city live,{" "}
                    <Link to="/live" className="underline">
                      click here
                    </Link>
                    .
                  </p>
                </div>
              </form>
            </>
          )}

          {isModalOpen && (
            <div className="fixed inset-0 flex items-center justify-center z-50">
              <div className="bg-black opacity-50 fixed inset-0"></div>
              <div className="bg-white rounded-lg p-5 z-10 w-[400px]">
                <div className="flex justify-end">
                  <button
                    onClick={closeModal}
                    className="text-gray-500 hover:text-gray-700 font-bold text-2xl"
                  >
                    &#10005;
                  </button>
                </div>
                <div className="mt-4 text-center">
                  <img
                    src={pending}
                    alt="success gif"
                    className="w-[100px] mx-auto"
                  />
                  <h2 className="text-black font-bold font-body font-mont text-xl leading-8 md:leading-10 lg:leading-12 xl:leading-14 mb-6 mt-6 md:mb-8">
                    Payment Pending
                  </h2>
                  <p>
                    Your payment is pending. Please view the ticket page to
                    check your payment status.
                  </p>
                  <button
                    className="bg-red-600 hover:bg-red-800 font-lato text-white font-body text-xl font-bold py-2 px-4 rounded mt-[20px] w-full"
                    onClick={closeModal}
                  >
                    Check Payment Status
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        {/* <Footer /> */}
      </motion.div>
    </>
  );
};

export default Userticket;
