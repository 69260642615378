import { Routes, Route, useLocation } from "react-router-dom";
import Home from "./pages/home";
import Ticketform from "./pages/ticketform";
// import Success from './pages/success';
import Stream from "./pages/stream";
import { AnimatePresence } from "framer-motion";
import { useEffect } from "react";
// import Pending from './pages/pending';
// import ProtectedRoute from './pages/ProtectedRoute';
import Userticket from "./pages/userticket";

function App() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <AnimatePresence>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/ticket" element={<Ticketform />} />
        {/* <Route path="/success" element={<Success />} /> */}
        <Route
          path="/live"
          element={
            // <ProtectedRoute>
            //   <Stream />
            // </ProtectedRoute>
            <Stream />
          }
        />
        {/* <Route path='/pending' element={<Pending />} /> */}
        <Route path="/view-ticket" element={<Userticket />} />
      </Routes>
    </AnimatePresence>
  );
}

export default App;
