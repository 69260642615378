import React, {useEffect, useState} from 'react';
import { motion } from "framer-motion";
import TicketHeader from '../components/ticketHeader';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ReactPlayer from 'react-player';
import {Link} from 'react-router-dom';
// import MuxPlayer from '@mux/mux-player-react';
// import Footer from '../components/footer';

const Stream = () => {

  const [email, setEmail] = useState('');
  const [ticketId, setTicketId] = useState('');
  const [loading, setLoading] = useState(false);
  const [ticketIdError, setTicketIdError] = useState('');
  const [emailError, setEmailError] = useState('');

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const validateForm = () => {
    let isValid = true;

    if (!email.trim()) {
      setEmailError('Email address is required');
      isValid = false;
    } else {
      setEmailError('');
    }

    if (!ticketId.trim()) {
      setTicketIdError('TicketId is required')
      isValid = false;
    } else {
      setTicketIdError('');
    }

    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!validateForm()) {
      return;
    }

    const streamData = {
      email,
      ticketId,
    };

    try {

      setLoading(true);

      const apiUrl = 'https://ticketingsystem-a38eed7cb83c.herokuapp.com/api/v1/ticket/check_in';

      const response = await fetch(apiUrl, {
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(streamData),
      });
      const result = await response.json();

      if (response.ok) {
        
        console.log(result)

        setData(result);
      } else {
        // console.error('Error:', response.status, response.statusText);
        console.log(result.message || 'An error occurred');
        setError(result.message)
      }
    } catch (error) {
      // console.error('Error:', error.message);
      setError('Ticket not found',  error.message);
    }
    setLoading(false);
  }  

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    const makeApiRequest = async () => {
      const muxTokenId = '3c41aa1e-4789-4ef0-870c-f5eb77a60a02';
      const muxTokenSecret = 'YisBAzV/UHg4VcwjBGtzWHAxT67VvcHCrLhcZWXCzu6xCVfbWNk2XJhix+2AdMFGSJotzxnDbL/';

      try {
        const response = await fetch('https://api.mux.com/video/v1/live-streams', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${btoa(`${muxTokenId}:${muxTokenSecret}`)}`,
          },
          body: JSON.stringify({
            playback_policy: ['public'],
            new_asset_settings: {
              playback_policy: ['public'],
            },
          }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        console.log('Response from Mux API:', data);
      } catch (error) {
        console.error('Error:', error.message);
      }
    };

    // makeApiRequest();
  }, []);

  return (
    <motion.div
      initial={{opacity: 0, y: 200}}
      animate={{opacity: 1, y: 0}}
      exit={{opacity:0, y: 0}}
      transition={{delay: 0.25, type: "spring"}}
    >
      <TicketHeader />
      {!data ? (
        <>
        
          <div className='text-center'>
            <p className="text-red-600 font-body font-lato font-bold text-lg p-3">
              Enter your email address and ticketId to view the live stream.
            </p>
          </div>
          <form
            onSubmit={handleSubmit}
            className="shadow-lg lg:w-[600px] sm:w-[90%] p-5 mx-auto"
          >
            <div className="mb-[15px]">
              <label className="text-red-600 font-body font-lato font-bold text-lg leading-6 md:leading-7 lg:leading-8 xl:leading-9">EMAIL</label>
              <input 
                type='email'
                placeholder='Enter your email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="border border-gray-300 py-2.5 px-2 w-full rounded-md focus:outline-none focus:border-red-600 my-[5px]"
              />
              <span className="text-red-300">{emailError}</span>
            </div>
        
            <div className="mb-[15px]">
              <label className="text-red-600 font-body font-lato font-bold text-lg leading-6 md:leading-7 lg:leading-8 xl:leading-9">TICKET ID</label>
              <input 
                type='text'
                placeholder='Enter your ticketId'
                value={ticketId}
                onChange={(e) => setTicketId(e.target.value)}
                className="border border-gray-300 py-2.5 px-2 w-full rounded-md focus:outline-none focus:border-red-600 my-[5px]"
              />
              <span className="text-red-300">{ticketIdError}</span>
            </div>
            <div className='text-center'>
              <p className="text-red-600 font-body font-lato font-bold text-lg">
                Don't know your ticketId?<Link to='/view-ticket' className='underline'>click here</Link>
              </p>
            </div>

            <div className='text-center'>
              <span className='text-red-600 font-bold'>{error}</span>
            </div>

            <button 
              className="bg-red-600 hover:bg-red-800 text-white font-lato font-body text-xl font-bold py-3 px-4 rounded mt-[30px] w-[200px] mx-auto block"
              onClick={handleSubmit}
            >
              {loading ? 'Submitting...' : 'Submit'}
            </button>
          </form>
        </>
      ) : (
        <>
          <section className="w-[90%] mx-auto py-[90px]">
            <div 
              className="w-full mt-5 rounded-10 h-[500px]"
              data-aos="fade-right" data-aos-duration="1000"
            >
              <ReactPlayer
                controls
                playing
                width="100%"
                height="100%"
                url = "https://stream.mux.com/tpWybh3XUe9bKLD9WvIzGGU02Mv2AaRANzeBTZw1D4T8.m3u8"
                loading='lazy'
              />
            </div>
          </section>
        </>
      )}
    </motion.div>
  )
}

export default Stream

// url format  = "https://stream.mux.com/{playback_id}.m3u8"

// Access token ID - 3c41aa1e-4789-4ef0-870c-f5eb77a60a02
// Secret Key - YisBAzV/UHg4VcwjBGtzWHAxT67VvcHCrLhcZWXCzu6xCVfbWNk2XJhix+2AdMFGSJotzxnDbL/