import React from 'react'
import transcorp from '../assets/logo1.png';
// import bitsSense from '../assets/logo2.png';
// import silverbird from '../assets/logo3.png';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
// import required modules
import { Pagination } from 'swiper/modules';

const Sponsors = () => {
  return (
    <section className='bg-red-600 text-center py-[30px]'>
      <div className=" w-[90%] mx-auto">
        <h2 className="text-white font-bold font-body font-mont text-2xl md:text-3xl lg:text-4xl xl:text-5xl leading-8 md:leading-10 lg:leading-12 xl:leading-14 mb-6 md:mb-8">Our Official Sponsors</h2>
        <Swiper
          slidesPerView={'auto'}
          spaceBetween={30}
          autoplay={{ delay: 2000, disableOnInteraction: false}}
          pagination={{
            clickable:true,
          }}
          modules={[Pagination]}
        >
          <SwiperSlide>
            <img 
              src={transcorp}
              alt='transcorp'
              className="w-[300px] mx-auto"
            />
          </SwiperSlide>

          {/* <SwiperSlide>
            <img 
              src={bitsSense}
              alt='bitsSense'
              className="w-[200px] mx-auto"
            />
          </SwiperSlide>

          <SwiperSlide>
            <img 
              src={silverbird}
              alt='silverBird cinema'
              className="w-[200px] mx-auto mt-20"
            />
          </SwiperSlide> */}
        </Swiper>
      </div>
    </section>
  )
}

export default Sponsors