import React, { useState, useEffect } from "react";
// import aboutImg from '../assets/about-img.jpg';
// import loader from '../assets/loader.gif';
import TicketHeader from "../components/ticketHeader";
import Footer from "../components/footer";
import { motion } from "framer-motion";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link, useNavigate } from "react-router-dom";
import { FaRegCopy } from "react-icons/fa";

const Ticketform = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  const [quantities, setQuantities] = useState(1);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [type, setType] = useState("");
  const [amount, setAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  // const [agree, setAgree] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState("");
  // errors state
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [typeError, setTypeError] = useState("");
  const [error, setError] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [responseData, setResponseData] = useState(null);
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(true);
  const [number, setNumber] = useState(3676463);
  const [isClicked, setIsClicked] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
    navigate("/view-ticket");
  };

  // useEffect(() => {
  //   openModal();
  // }, []);

  const handleCopy = () => {
    const copyText = document.getElementById("numberToCopy");

    const range = document.createRange();
    range.selectNode(copyText);

    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);

    try {
      document.execCommand("copy");
      window.getSelection().removeAllRanges();
      setIsClicked(true);
    } catch (err) {
      alert("Unable to copy");
    }
    setTimeout(() => {
      setIsClicked(false);
    }, 1000);
  };

  const handleNumberChange = (e) => {
    setNumber(e.target.value);
  };

  // const handleSuccessfulPayment = () => {
  //   setIsModalOpen(false);

  //   navigate('/view-ticket');

  // if data.body.status is pending, don't generate ticket at all
  // if (data.body && data.body.status === 'SUCCESS') {
  //   setResponseData(data.body);
  //   setIsModalOpen(true);
  // } else {
  //
  // }
  // }

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const ticketTypes = [
    { label: "Select ticket type", value: "0" },
    { label: "Watch Live Only (Coming soon)", value: "1,500" },
    { label: "Regular", value: "7,000" },
    { label: "VIP", value: "20,000" },
    { label: "Silver Table of 10", value: "1,000,000" },
    { label: "Gold Table of 10", value: "2,000,000" },
  ];

  useEffect(() => {
    // Add a blinking animation to the error message
    const blinkInterval = setInterval(() => {
      const errorElement = document.getElementById("error-message");
      if (errorElement) {
        errorElement.classList.toggle("opacity-0");
      }
    }, 1000);

    return () => clearInterval(blinkInterval);
  }, [error]);

  const handleTypeChange = (e) => {
    const selectedValue = e.target.value;
    const selectedLabel = ticketTypes.find(
      (option) => option.value === selectedValue
    )?.label;

    setSelectedLabel(selectedLabel);

    setType(selectedValue);

    const price = parseFloat(selectedValue.replace(/,/g, ""));

    const newAmount = price * quantities;

    setAmount(newAmount);
  };

  const handleQuantityChange = (e) => {
    const newQuantity = e.target.value;
    setQuantities(newQuantity);

    const price = parseFloat(type.replace(/,/g, ""));
    const newAmount = price * newQuantity;
    // setAmounts(newAmount.toLocaleString());
    setAmount(newAmount);
  };

  const validateForm = () => {
    let isValid = true;

    if (!firstName.trim()) {
      setFirstNameError("First name is required");
      isValid = false;
    } else {
      setFirstNameError("");
    }

    if (!lastName.trim()) {
      setLastNameError("Last name is required");
      isValid = false;
    } else {
      setLastNameError("");
    }

    if (!phone.trim()) {
      setPhoneError("Phone number is required");
      isValid = false;
    } else {
      setPhoneError("");
    }

    if (!email.trim()) {
      setEmailError("Email address is required");
      isValid = false;
    } else {
      setEmailError("");
    }

    if (!type.trim()) {
      setTypeError("Ticket type is required");
      isValid = false;
    } else {
      setTypeError("");
    }

    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    if (!selectedOption) {
      alert("Please select a payment option before proceeding.");
      return;
    }

    setLoading(true);
    setError(null);

    let type = selectedLabel;

    let quantity = Number(quantities);

    const formData = {
      firstName,
      lastName,
      phone,
      email,
      quantity,
      type,
      amount,
    };

    if (selectedOption === "card") {
      try {
        const apiUrl =
          "https://ticketingsystem-a38eed7cb83c.herokuapp.com/api/v1/card/init";

        // console.log(formData);

        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });

        if (response.ok) {
          const data = await response.json();

          if (data.body && data.body.payment_url) {
            window.location.href = data.body.payment_url;
          } else {
            setError("Unexpected response. Please try again.");
            // console.log('Unexpected API response:', data);
          }
        } else {
          setError("Error submitting form. Please try again.");
          // console.log('Error submitting form', response.statusText);
        }
      } catch (error) {
        setError("Error submitting form. Please try again.");
        // console.log('Error submitting form', error);
      }
    } else if (selectedOption === "transfer") {
      try {
        const apiUrl =
          "https://ticketingsystem-a38eed7cb83c.herokuapp.com/api/v1/virtual_account/init";

        // console.log(formData);

        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(formData),
        });

        if (response.ok) {
          const data = await response.json();
          // console.log(data);
          // console.log(data.body);
          setResponseData(data.body);
        } else {
          // console.log('Error enerating payment details, please try agin');
          setError("Error generating payment details, please try agin");
        }
      } catch (error) {
        setError("Something went wrong, please try again", error);
      }
    }
    setLoading(false);
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 200 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 0 }}
      transition={{ delay: 0.25, type: "spring" }}
    >
      <TicketHeader />
      <section className="w-full py-[30px] mx-auto">
        <div
          className="w-full lg:w-[700px] mt-[50px] mx-auto"
          data-aos="fade-down"
          data-aos-duration="1000"
        >
          <form onSubmit={handleSubmit} className="shadow-lg w-full p-5">
            <div className="mb-[15px]">
              <label className="text-red-600 font-body font-lato font-bold text-lg leading-6 md:leading-7 lg:leading-8 xl:leading-9">
                FIRST NAME
              </label>
              <input
                type="text"
                placeholder="Enter your first name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className="border border-gray-300 py-2.5 px-2 w-full rounded-md focus:outline-none focus:border-red-600 my-[5px]"
              />
              <span className="text-red-300">{firstNameError}</span>
            </div>

            <div className="mb-[15px]">
              <label className="text-red-600 font-body font-lato font-bold text-lg leading-6 md:leading-7 lg:leading-8 xl:leading-9">
                LAST NAME
              </label>
              <input
                type="text"
                placeholder="Enter your last name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className="border border-gray-300 py-2.5 px-2 w-full rounded-md focus:outline-none focus:border-red-600 my-[5px]"
              />
              <span className="text-red-300">{lastNameError}</span>
            </div>

            <div className="mb-[15px]">
              <label className="text-red-600 font-body font-lato font-bold text-lg leading-6 md:leading-7 lg:leading-8 xl:leading-9">
                PHONE NUMBER
              </label>
              <input
                type="tel"
                placeholder="Enter your phone number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className="border border-gray-300 py-2.5 px-2 w-full rounded-md focus:outline-none focus:border-red-600 my-[5px]"
              />
              <span className="text-red-300">{phoneError}</span>
            </div>

            <div className="mb-[15px]">
              <label className="text-red-600 font-body font-lato font-bold text-lg leading-6 md:leading-7 lg:leading-8 xl:leading-9">
                EMAIL ADDRESS
              </label>
              <input
                type="email"
                placeholder="Enter your email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="border border-gray-300 py-2.5 px-2 w-full rounded-md focus:outline-none focus:border-red-600 my-[5px]"
              />
              <span className="text-red-300">{emailError}</span>
            </div>

            <div className="mb-[15px]">
              <label className="text-red-600 font-body font-lato font-bold text-lg leading-6 md:leading-7 lg:leading-8 xl:leading-9">
                TICKET TYPE
              </label>
              <select
                className="border border-gray-300 py-3 px-2 w-full rounded-md focus:outline-none focus:border-red-600 my-[5px] bg-transparent"
                onChange={handleTypeChange}
              >
                {ticketTypes.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              <span className="text-red-300">{typeError}</span>
            </div>

            <div className="mb-[15px]">
              <label className="text-red-600 font-body font-lato font-bold text-lg leading-6 md:leading-7 lg:leading-8 xl:leading-9">
                NUMBER OF TICKETS(S)
              </label>
              <select
                className="border border-gray-300 py-3 px-2 w-full rounded-md focus:outline-none focus:border-red-600 my-[5px] bg-transparent"
                value={quantities}
                onChange={handleQuantityChange}
              >
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((number) => (
                  <option key={number} value={number}>
                    {number}
                  </option>
                ))}
              </select>
            </div>

            <div className="mb-[15px]">
              <label className="text-red-600 font-body font-lato font-bold text-lg leading-6 md:leading-7 lg:leading-8 xl:leading-9">
                AMOUNT IN NAIRA (#)
              </label>
              <input
                type="amount"
                placeholder="Amount in naira"
                value={amount}
                readOnly
                onChange={(e) => setAmount(e.target.value)}
                className="border border-gray-300 py-2.5 px-2 w-full rounded-md focus:outline-none focus:border-red-600 my-[5px]"
              />
            </div>

            <div className="flex justify-around items-center my-5">
              {/* <label className="text-lg">
                <input
                  type="radio"
                  value="card"
                  checked={selectedOption === "card"}
                  onChange={handleRadioChange}
                  className="mr-2"
                  disabled
                />
                Pay with Card
              </label> */}
              <label className="text-lg">
                <input
                  type="radio"
                  value="transfer"
                  checked={selectedOption === "transfer"}
                  onChange={handleRadioChange}
                  className="mr-2"
                />
                Pay via Bank Transfer
              </label>
            </div>

            {/**
            <h4 className="font-bold text-red-600 font-lato text-md text-center font-body">
              Payment with card is temporarily unavailable.
            </h4>
            */}
            <h4 className="font-bold text-red-600 font-lato text-md text-center font-body">
              Please note: A 5% service charge will be applied to the total
              amount.
            </h4>

            {error && (
              <div
                id="error-message"
                className="error-message transition-opacity duration-500 ease-in-out opacity-100 text-red-600 font-body font-lato text-center text-xl my-5"
              >
                {error}
              </div>
            )}

            <button
              className="bg-red-600 hover:bg-red-800 text-white font-lato font-body text-xl font-bold py-3 px-4 rounded mt-[50px] w-[200px] mx-auto block"
              type="submit"
              disabled={loading}
              onClick={handleSubmit}
            >
              {loading ? "Loading..." : "Buy Ticket"}
            </button>

            <p className="font-bold text-red-600 font-lato text-md text-center font-body my-5">
              By clicking Buy Ticket, you agree to our
              <Link className="underline text-blue-600 ml-1">Terms of Use</Link>
            </p>
          </form>
        </div>
      </section>
      {responseData && isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-black opacity-50 fixed inset-0"></div>
          <div className="bg-white rounded-lg p-5 z-10 w-[400px]">
            <div className="flex justify-end">
              <button
                onClick={closeModal}
                className="text-gray-500 hover:text-gray-700 font-bold text-2xl"
              >
                &#10005;
              </button>
            </div>
            <div className="mt-4 text-center">
              <p className="text-black font-body font-mont text-md">
                ACCOUNT DETAILS
              </p>
              <div className="flex items-center justify-center">
                <h2
                  className="text-black font-bold font-body font-mont text-2xl leading-8 md:leading-10 lg:leading-12 xl:leading-14 mb-4 mt-6 md"
                  id="numberToCopy"
                  value={number}
                  onChange={handleNumberChange}
                >
                  {responseData.accountNumber}
                </h2>

                <FaRegCopy
                  onClick={handleCopy}
                  className={`text-2xl cursor-pointer ml-[15px] ${
                    isClicked ? "text-red-600" : "text-black"
                  }`}
                />
              </div>
              <h4 className="text-black font-bold font-body font-mont text-md leading-relaxed">
                {responseData.bankName}
              </h4>
              <p className="text-black font-body font-mont text-md mt-[15px]">
                ACCOUNT NAME
              </p>
              <h4 className="text-black font-bold font-body font-mont text-md leading-relaxed">
                {responseData.accountName}
              </h4>
              <p className="text-black font-body font-bold font-mont text-md mt-[15px]">
                Amount: NGN {responseData.amount}
              </p>
              <button
                className="bg-red-600 hover:bg-red-800 font-lato text-white font-body text-xl font-bold py-2 px-4 rounded mt-[20px] w-full"
                onClick={closeModal}
              >
                View Payment Status
              </button>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </motion.div>
  );
};

export default Ticketform;
