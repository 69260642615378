import React from 'react'
import bitsSense from '../assets/logo2.png';
import { Link } from 'react-router-dom';

const TicketHeader = () => {
  return (
    <nav className="flex justify-between items-center w-full h-[70px] px-[20px] py-10">
      <div className='logo'>
        <img 
          src={bitsSense}
          alt='bitsSense'
          className="w-[70px] cursor-pointer"
        />
      </div>

      <Link to='/'>
        <button className="bg-red-600 hover:bg-red-800 text-white font-lato font-body text-xl font-bold py-2 px-4 rounded my-[20px] w-[150px]">Go to home</button>
      </Link>
    </nav>
  )
}

export default TicketHeader